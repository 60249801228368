/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={6} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              What's in a name?
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              House Culture Brewing is a fermentation company.  The idea for our name came when we found that some wild
              farmhouse ale beers are sometimes described to have a "House Culture" because it describes the community
              of bacteria and yeast that transforms them into its final combination of flavors and aromas that make
              those beers so unique.  We wanted to expand on that idea and see how it applies to fermenting different
              foods with their own cultures unique to each food.  However, House Culture Brewing is much more than that.
              We want to introduce foods to our community and customers from all around the world, starting with our
              classic German Sauerkraut, our Korean inspired Kimchi, and our Chile Curtido, a staple food from Mexico.
              But that's just the beginning! We plan to continue to produce and introduce new and different fermented
              foods from different cultures around the world.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Promise
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              At House Culture Brewing we are dedicated to producing simple, real foods which are made with only real
              ingredients.  Real is better, this means No Preservatives, No Artificial Flavors, No Compromises.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
