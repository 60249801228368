import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const mock = [
    {
        title: 'Fermented Foods',
        description: 'Naturally Fermented, Packed with Probiotics',
        href: '/products/Fermented',
        image: 'https://houseculturebrewing.s3.amazonaws.com/77cbf1db-7340-4035-9135-4615f4fce306.png',
    },
    {
        title: 'Kombucha',
        description: 'Deliciously Refreshing, Naturally Fermented',
        href: '/products/Kombucha',
        image: 'https://houseculturebrewing.s3.amazonaws.com/29937cd5-aeb6-4e36-95b7-a0c638511495.jpg',
    },
    {
        title: 'Hop-Water',
        description: 'Sparkling, Hop-Infused Water',
        href: '/products/HopWater',
        image: 'https://houseculturebrewing.s3.amazonaws.com/b369c6c6-43ad-4e58-82b9-9af362dc3645.jpg',
    },
];

const ProductHighlight = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (
        <Box>
            <Box marginBottom={4}>
                <Typography variant={'h5'} fontWeight={700}>
                    Discover our top items
                </Typography>
            </Box>
            <ImageList
                variant="quilted"
                cols={2}
                rowHeight={400}
                gap={isMd ? 32 : 16}
                sx={{ margin: 0 }}
            >
                {mock.map((item, i) => (
                    <ImageListItem
                        key={i}
                        cols={isMd ? 1 : 2}
                        rows={isMd ? (i === 0 ? 2 : 1) : 1}
                        sx={{ borderRadius: 2 }}
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                filter: 'brightness(0.7)',
                                borderRadius: 8,
                            }}
                        />
                        <Box
                            position={'absolute'}
                            top={0}
                            left={0}
                            right={0}
                            width={1}
                            padding={4}
                            zIndex={2}
                        >
                            <Typography
                                color={'common.white'}
                                fontWeight={700}
                                variant={'h4'}
                            >
                                {item.title}
                            </Typography>
                            <Typography color={'common.white'} variant={'h6'}>
                                {item.description}
                            </Typography>
                            <Button
                                component={Link}
                                href={item.href}
                                variant={'contained'}
                                color={'primary'}
                                sx={{ marginTop: 2 }}
                            >
                                Shop now
                            </Button>
                        </Box>
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
};

export default ProductHighlight;
